// Types Imports
import type { NavItem } from '@/types/include';

export const navigation: NavItem<string>[] = [
  // {
  //   label: 'Accueil',
  //   path: '/',
  //   description: 'Accueil',
  //   icon: 'home.svg',
  // },
  // {
  //   label: "Vision d'ensemble",
  //   path: '/sommaire',
  //   description: "Vision d'ensemble",
  // },
  {
    label: 'Besoins des entreprises',
    path: '/entreprises',
    description: 'Besoins des entreprises',
    subItems: [
      {
        label: 'Typologie des entreprises',
        path: '/entreprises/ent',
        description: 'KPI',
        filters: [
          'code_secteur_naf732',
          'num_branche_pro',
          'num_region',
          'code_departement',
        ],
      },
      {
        label: 'Typologie des établissements',
        path: '/entreprises/etab',
        description: 'KPI',
        filters: [
          'code_secteur_naf732',
          'num_branche_pro',
          'num_region',
          'code_departement',
        ],
      },
      {
        label: 'Typologie des autoentrepreneurs',
        path: '/entreprises/auto',
        description: 'KPI',
        filters: ['code_secteur_naf732', 'num_region', 'code_departement'],
      },
      {
        label: 'Sociodémographie des salariés',
        path: '/entreprises/salaries',
        description: 'KPI',
        filters: [
          'code_secteur',
          'num_branche_pro',
          'num_region',
          'code_departement',
        ],
      },
      {
        label: 'Recrutement',
        path: '/entreprises/recrutement',
        description: 'KPI',
        subItems: [
          {
            label: 'Dynamique de recrutement',
            path: '/entreprises/recrutement/dynamique',
            description: 'KPI',
            filters: ['num_branche_pro', 'num_region', 'code_departement'],
          },
          {
            label: 'Tension au recrutement',
            path: '/entreprises/recrutement/tension',
            description: 'KPI',
            filters: ['num_branche_pro', 'num_region'],
          },
        ],
      },
    ],
  },
  {
    label: 'Concurrence & cartographie des organismes de formation',
    path: '/concurrence',
    description: 'Concurrence (OF)',
  },
  {
    label: 'Viviers',
    path: '/viviers',
    description: 'Viviers',
    subItems: [
      {
        label: 'Sociodémographie du territoire',
        path: '/viviers/sociodemo',
        description: 'KPI',
        filters: ['num_region', 'code_departement'],
      },
      {
        label: 'Enseignement secondaire',
        path: '/viviers/secondaire',
        description: 'KPI',
        filters: ['num_region', 'code_departement'],
      },
      {
        label: 'Enseignement supérieur',
        path: '/viviers/superieur',
        description: 'KPI',
        filters: ['num_region', 'code_departement'],
      },
      {
        label: 'Voie professionnelle',
        path: '/viviers/professionnelle',
        description: 'KPI',
        filters: ['num_region', 'code_departement'],
      },
      {
        label: 'Alternance et formation continue',
        path: '/viviers/alternance',
        description: 'KPI',
      },
    ],
  },
];
