// Next
import { useRouter } from 'next/router';
import { useState } from 'react';

// Lib
import { Disclosure, Transition } from '@headlessui/react';

// Types
import type { NavItem } from '@/types/include';
import type { ParsedUrlQuery } from 'querystring';

// Config
import { navigation } from '@/config/navigation'; // all navigation routes config file

// Icons
import { ChevronDownIcon } from '@heroicons/react/20/solid';

type DisclosureProps = {
  title: string;
  list: NavItem<string>[];
  defaultOpen?: boolean;
  setModalParams: (path: string | undefined, query: ParsedUrlQuery) => void;
};

// function hasFilterToDelete(query: ParsedUrlQuery, filterToPreserve: string[] | undefined) {
//   console.log({ query, filterToPreserve });
//   if (!filterToPreserve) return true;
//   Object.keys(query).forEach((key) => {
//     if (!filterToPreserve.includes(key)) {
//       return true;
//     }
//   });
//   return false;
// }

function getQueryToPreserve(
  query: ParsedUrlQuery,
  filterToPreserve: string[] | undefined
) {
  if (!filterToPreserve) return {};
  const queryToPreserve = new Map(Object.entries(query));
  Object.keys(query).forEach((key) => {
    if (!filterToPreserve.includes(key)) {
      queryToPreserve.delete(key);
    }
  });
  return Object.fromEntries(queryToPreserve) as Record<string, string>;
}

// modal for confirmation of changing route
function ConfirmationModal({
  setOpen,
  params,
}: {
  setOpen: (open: boolean) => void;
  params: { path: string; query: ParsedUrlQuery };
}) {
  const router = useRouter();

  const handleConfirm = () => {
    void router.push({ pathname: params.path, query: params.query });
    setOpen(false);
  };

  return (
    <div className='fixed z-[100] inset-0 overflow-y-auto'>
      <div className='flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
        {/* overlay */}
        <div
          className='fixed inset-0 transition-opacity'
          onClick={() => {
            setOpen(false);
          }}
        >
          <div className='absolute inset-0 bg-gray-500 opacity-75'></div>
        </div>
        {/* content */}
        <span className='hidden sm:inline-block sm:align-middle sm:h-screen'></span>
        &#8203;
        <div className='inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-11/12'>
          <div className='bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4'>
            <div className='flex flex-wrap'>
              <p className='text-center text-xs leading-5 text-[#283C8C] w-full font-semibold'>
                {
                  '⚠️ Attention, vous êtes en train de changer de tableau de bord, certains filtres pourraient ne pas être conservés.'
                }
              </p>
              <span className='text-center text-xs leading-2 text-gray-900 w-full mt-2'>
                {'Voulez-vous continuer ?'}
              </span>
              <div className='flex justify-center w-full mt-2'>
                <button
                  onClick={() => {
                    setOpen(false);
                  }}
                  className='bg-white hover:bg-gray-100 text-gray-700 text-xs font-semibold py-1 px-4 border border-gray-400 rounded shadow mr-2'
                >
                  Non
                </button>
                <button
                  onClick={() => {
                    handleConfirm();
                  }}
                  className='bg-[#283C8C] hover:bg-[#202952] text-white text-xs font-semibold py-1 px-4 border border-gray-400 rounded shadow'
                >
                  Oui
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DisclosureCustom({ title, list, setModalParams }: DisclosureProps) {
  // check if the current route is active
  const router = useRouter();
  return (
    <Disclosure defaultOpen>
      {({ open }) => {
        // if (open !== disclosure.per) setDisclosure({ ...disclosure, per: open });
        return (
          <>
            <Disclosure.Button className='flex w-full justify-between rounded-lg bg-slate-100 px-4 py-2 text-left text-sm text-black hover:bg-slate-50'>
              <span>{title}</span>

              <ChevronDownIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-black`}
              />
            </Disclosure.Button>
            <Transition
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100 relative z-50'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
            >
              {
                <Disclosure.Panel className='text-sm font-medium pt-2 px-1 cursor-pointer'>
                  {list.map((item: NavItem<string>, index) => {
                    return (
                      <div className='py-1 pl-4' key={index}>
                        <span
                          onClick={() => {
                            Object.keys(router.query).length > 0
                              ? setModalParams(
                                  item.path,
                                  getQueryToPreserve(router.query, item.filters)
                                )
                              : void router.push({
                                  pathname: item.subItems
                                    ? item.subItems[0]?.path
                                    : item.path,
                                  query: getQueryToPreserve(
                                    router.query,
                                    item.filters
                                  ),
                                });
                          }}
                          // href={{ pathname: item.subItems ? item.subItems?.[0]?.path : item.path, query: getQueryToPreserve(router.query, item.filters) }}
                          className={
                            'text-gray-700 hover:text-primary-blue-400' +
                            (router.pathname === item.path ? ' font-bold' : '')
                          }
                        >
                          {item.label}
                        </span>
                        {item.subItems
                          ? item.subItems.map(
                              (subItem: NavItem<string>, index) => (
                                <div className='py-1 pl-4' key={index}>
                                  <span
                                    onClick={() => {
                                      Object.keys(router.query).length > 0
                                        ? setModalParams(
                                            subItem.path,
                                            getQueryToPreserve(
                                              router.query,
                                              subItem.filters
                                            )
                                          )
                                        : void router.push({
                                            pathname: subItem.path,
                                            query: getQueryToPreserve(
                                              router.query,
                                              subItem.filters
                                            ),
                                          });
                                    }}
                                    // href={{ pathname: subItem.path, query: getQueryToPreserve(router.query, subItem.filters) }}
                                    className={
                                      'text-gray-400 hover:text-primary-blue-400 text-xs' +
                                      (router.pathname === subItem.path
                                        ? ' font-bold text-gray-600'
                                        : '')
                                    }
                                  >
                                    {subItem.label}
                                  </span>
                                </div>
                              )
                            )
                          : null}
                      </div>
                    );
                  })}
                </Disclosure.Panel>
              }
            </Transition>
          </>
        );
      }}
    </Disclosure>
  );
}

export default function NavigationMenu() {
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const [modalParams, setParams] = useState({ path: '', query: {} });

  const setModalParams = (path: string | undefined, query: ParsedUrlQuery) => {
    setOpen(true);
    setParams({ path: path ?? '', query });
  };

  return (
    <div className='relative w-auto md:w-full p-3'>
      {open ? (
        <ConfirmationModal setOpen={setOpen} params={modalParams} />
      ) : null}
      <div className='bg-white rounded-lg'>
        {navigation.map((item: NavItem<string>) => (
          <div className=' mb-1' key={item.label}>
            {item.subItems ? (
              <DisclosureCustom
                title={item.label}
                list={item.subItems}
                setModalParams={setModalParams}
              />
            ) : (
              <span
                onClick={() => {
                  Object.keys(router.query).length > 0
                    ? setModalParams(
                        item.path,
                        getQueryToPreserve(router.query, item.filters)
                      )
                    : void router.push({
                        pathname: item.path,
                        query: getQueryToPreserve(router.query, item.filters),
                      });
                }}
                // href={{ pathname: item.path, query: getQueryToPreserve(router.query, item.filters) }}
                className={`flex w-full rounded-lg ${
                  router.pathname === item.path
                    ? 'bg-slate-300'
                    : 'bg-slate-100'
                } px-4 py-2 text-left text-sm text-black hover:bg-slate-50 cursor-pointer`}
              >
                <>
                  <span className='w-full'>{item.label}</span>
                </>
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
