/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { isEmpty } from 'lodash';
import { utils, writeFile } from 'xlsx';

const createNumberCell = (
  cell: any,
  newRow: any[],
  possibleNumberInCell: any
) => {
  let formating = typeof cell === 'string' ? cell.replace(/\d/g, '0') : '';
  while (formating.startsWith('00')) {
    formating = formating.substring(1);
  }

  // round float to 2 decimal
  const pointIndex = formating.indexOf('.');
  if (pointIndex !== -1) {
    const afterPoint = formating.split('.')[1];
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const zeroCount = (afterPoint!.match(new RegExp('0', 'g')) ?? []).length;
    const debut = formating.substring(0, pointIndex + 1);
    formating = debut + '00' + formating.substring(pointIndex + 1 + zeroCount);
  }

  newRow.push({
    t: 'n', // numeric cell
    v:
      typeof cell === 'string'
        ? cell.endsWith('%') || cell.startsWith('%')
          ? possibleNumberInCell / 100
          : possibleNumberInCell
        : cell, // underlying value
    z: formating, //, // number format
    w: cell, // formatted text
  });
};

const convertStringNumberToNumberForPage = (pages: any) => {
  const pagesWithNumber: any[][] = [];
  //convert string of number to cell number
  pages.forEach((row: any[]) => {
    const newRow: any[] = [];
    row.forEach((cell: any) => {
      const possibleNumberInCell = Number(cell);
      const possibleNumberWithEndingSymbolInCell = Number(
        typeof cell === 'string' ? cell.substring(0, cell.length - 1) : cell
      );
      const possibleNumberWithBegginingSymbolInCell = Number(
        typeof cell === 'string' ? cell.substring(1) : cell
      );
      if (!isNaN(possibleNumberInCell)) {
        createNumberCell(cell, newRow, possibleNumberInCell);
      } else if (
        !isNaN(possibleNumberWithEndingSymbolInCell) &&
        (cell.endsWith('%') || cell.endsWith('€'))
      ) {
        createNumberCell(cell, newRow, possibleNumberWithEndingSymbolInCell);
      } else if (
        !isNaN(possibleNumberWithBegginingSymbolInCell) &&
        (cell.startsWith('%') || cell.startsWith('€'))
      ) {
        createNumberCell(cell, newRow, possibleNumberWithBegginingSymbolInCell);
      } else {
        newRow.push(cell);
      }
    });
    pagesWithNumber.push(newRow);
  });

  return pagesWithNumber;
};

// format sheet name https://support.microsoft.com/en-us/office/rename-a-worksheet-3f1f7148-ee83-404d-8ef0-9ff99fbad1f9
const formatSheetName = (name: string) => {
  // remove forbidden characteres
  const pattern = /[\\/?*:[]/g;
  let result = name.replace(pattern, '');
  // max size for sheet name is 31 characters
  result = result.substring(0, 30);
  // remove leading and trailing apostrophes
  while (result.startsWith("'")) {
    result = result.substring(1);
  }
  while (result.endsWith("'")) {
    result = result.substring(0, result.length - 1);
  }
  // remove forbidden name "History"
  if (result === 'History' || result === '') return 'Sheet';
  return result;
};

export const generateCSV = (
  data: Record<string, string[][]>,
  filename: string,
  keyfigures: Record<string, (string | number)[][]>,
  cb?: () => void
  // sheet_name?: string
) => {
  /* create a new workbook */
  const wb = utils.book_new();

  /* create pages */
  /**
   * data = { page1_name: page1_data, page2_name: page2_data }
   */

  if (!isEmpty(keyfigures)) {
    const keyfigures_ws = utils.aoa_to_sheet([]);
    let idx = 1;
    for (const key in keyfigures) {
      if (key === 'type') continue;

      utils.sheet_add_aoa(keyfigures_ws, [[key]], { origin: `A${idx}` });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      utils.sheet_add_aoa(keyfigures_ws, keyfigures[key]!, {
        origin: `A${idx + 1}`,
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      idx = idx + 1 + keyfigures[key]!.length + 1; // +1: space for title, +1: space left between key figures
    }

    // "Filtres" is an overwritten value, it must be variable, not hardcoded
    utils.book_append_sheet(
      wb,
      keyfigures_ws,
      Object.keys(keyfigures).includes('type')
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        (keyfigures.type![0]?.[0] as string)
        : 'Filtres'
    );
  }

  for (const page in data) {
    const result = formatSheetName(page);
    const pagesWithNumber = convertStringNumberToNumberForPage(data[page]);

    const ws = utils.aoa_to_sheet(pagesWithNumber);
    utils.book_append_sheet(wb, ws, result);
  }

  /* generate XLSX file and send to client */
  writeFile(wb, `${filename}.xlsx`);

  cb?.();
};
