// Next imports
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { generateFullPagePNG } from 'ui/export/png';

// Components Imports
import NavigationMenu from '@/components/NavigationMenu';
import { useExportExcelContext } from '@/context/ExportExcelDataContext';
import { Popover, Transition } from '@headlessui/react';

// Helpers Imports
import { generateCSV } from '@ui/export/excel';
import { isEmpty, pick, set } from 'lodash';

const cognitoClientId = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID;
const cognitoDomain = process.env.NEXT_PUBLIC_COGNITO_DOMAIN;

type SidebarProps = {
  hideSidebar: boolean;
  setHideSidebar: (hideSidebar: boolean) => void;
  setGlobalLoading: (loading: boolean) => void;
  setShowModal: (show: boolean) => void;
};

export default function Sidebar({
  hideSidebar,
  setHideSidebar,
  // setGlobalLoading,
  setShowModal,
}: SidebarProps) {
  // hooks
  const router = useRouter();
  const { data: session } = useSession();
  const { data: excelData } = useExportExcelContext();
  // states
  const [showWarningMessage, setShowWarningMessage] = useState<
    string | undefined
  >('');
  const [showExportIcons, setShowExportIcons] = useState(false);
  const [showExportIconsMobile, setShowExportIconsMobile] = useState(false);
  // variables
  const userName = session?.user.name ?? session?.user.email;
  const userNameWithoutAt = userName?.split('@')[0];

  const federatedSignOut = () => {
    const currantDomain = window.location.origin;
    window.location.replace(
      `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${currantDomain}/auth/signout`
    );
  };

  // functions
  const handleExportReportPNG = () => {
    setShowExportIcons(false);
    setShowWarningMessage('🔘 Export PNG en cours...');
    setTimeout(() => {
      setShowWarningMessage(undefined);
      const element = document.getElementById('__next')!;
      const pathname = router.pathname.split('/');
      const filename =
        pathname.length > 2
          ? `galileo-${router.pathname.split('/')[1]}_${router.pathname.split('/')[2]}`
          : `galileo-${router.pathname.split('/')[1]}`;

      generateFullPagePNG(element, filename, () => {
        setShowExportIcons(false);
      });
    }, 1000);
  };

  const handleExportReportExcel = () => {
    setShowExportIcons(false);
    setShowWarningMessage('🔘 Export Excel en cours...');
    setTimeout(() => {
      setShowWarningMessage(undefined);
      const pathname = router.pathname.split('/');
      const filename =
        pathname.length > 2
          ? `galileo-${router.pathname.split('/')[1]}_${router.pathname.split('/')[2]}`
          : `galileo-${router.pathname.split('/')[1]}`;

      // xlsx file content
      const xlsxPages: Record<string, string[][]> = {};

      const appliedFilters = !isEmpty(excelData.filters) && [
        ['Filtres appliqués'],
        ...excelData.filters
          .filter((filter) => filter.value !== null)
          .map((filter) => [
            filter.field ?? filter.label,
            typeof filter.value === 'object'
              ? (filter.value as (number | string | boolean)[]).join(', ')
              : filter.value,
          ]),
        [''], // return to line
      ];

      // _qv: query value
      Object.values(excelData.queries).forEach((_qv) => {
        const _page = _qv.title;
        const _colsmapping = _qv.columns;
        const _rows = _qv.returnedRows;
        const _suffixes = _qv.suffixes;

        console.log({ _suffixes, _qv });

        const _content = [];

        // add applied filters to the page content if there are any
        appliedFilters && _content.push(...appliedFilters);

        // add columns labels to the page content
        let _columns: string[] = [];
        // filter id colums
        const _rowKeys =
          _rows?.[0] &&
          Object.keys(_rows[0]).filter(
            (key) => !(key.startsWith('num_') || key.startsWith('code_'))
          );
        const _usedKeys = Object.keys(_colsmapping ?? {}).filter((key) =>
          _rowKeys?.includes(key)
        );
        if (_rowKeys?.includes('annee')) _usedKeys.unshift('annee');

        if (_colsmapping) {
          _columns = _usedKeys
            .filter((key) => typeof key === 'string')
            .map((key) => {
              // annee is a special case, we need to add it to the columns
              if (key === 'annee') return 'Année';
              return _colsmapping[key]!;
            });

          // add columns labels to the page content
          _content.push(_columns);

          // add rows to the page content
          _content.push(
            ...(_rows ?? []).map((row) => {
              const rowContent = { ...row };
              const _filtredUsedKeys = _usedKeys.filter(
                (key) =>
                  Object.keys(_colsmapping).includes(key) || key === 'annee'
              );
              _usedKeys.forEach((key) => {
                if (key === 'evolution_rate')
                  rowContent[key] = rowContent[key]
                    ? (rowContent[key] as number).toFixed(0)
                    : '';
                else rowContent[key] = rowContent[key]?.toString() ?? '';
              });

              // add suffixes to the page content
              _suffixes &&
                Object.keys(_suffixes).forEach((key) => {
                  if (rowContent[key] && _suffixes[key])
                    rowContent[key] += _suffixes[key]!;
                });

              return Object.values(pick(rowContent, _filtredUsedKeys));
            })
          );
        }

        // before we set the page content, we need to applied filters and columns labels
        set(xlsxPages, _page, _content);
      });

      // console.log({ xlsxPages, filename, _f: excelData.filters, excelData });
      generateCSV(xlsxPages, filename, {}, () => {
        setShowExportIcons(!showExportIcons);
      });
    }, 1000);
  };

  const handleShowMethodology = () => {
    setShowModal(true);
  };

  return (
    <>
      <button
        data-drawer-target='sidebar-multi-level-sidebar'
        data-drawer-toggle='sidebar-multi-level-sidebar'
        aria-controls='sidebar-multi-level-sidebar'
        type='button'
        className='inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200'
      >
        <span className='sr-only'>Open sidebar</span>
        <svg
          className='w-6 h-6'
          aria-hidden='true'
          fill='currentColor'
          viewBox='0 0 20 20'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            clipRule='evenodd'
            fillRule='evenodd'
            d='M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z'
          ></path>
        </svg>
      </button>
      <aside
        className={`fixed top-0 left-0 z-40 w-[20rem] h-screen transition-transform -translate-x-full duration-500  ${
          hideSidebar
            ? 'sm:translate-x-[-17rem] shadow-lg rounded-lg'
            : 'sm:translate-x-0'
        }`}
        aria-label='Sidebar'
      >
        {/* button in sidebar to close sidebar */}
        <button
          type='button'
          className='text-gray-400 z-40 bg-gray-100 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center'
          onClick={() => {
            setHideSidebar(!hideSidebar);
          }}
        >
          {!hideSidebar ? (
            <svg
              aria-hidden='true'
              className='w-5 h-5'
              fill='currentColor'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                clipRule='evenodd'
              ></path>
            </svg>
          ) : (
            <Image
              src={'/komete-icon.svg'}
              width={22}
              height={22}
              alt='Komete'
              className='rounded hover:bg-slate-200'
            ></Image>
          )}
          <span className='sr-only'>Close menu</span>
        </button>
        {!hideSidebar ? (
          <div
            className={`h-full px-3 py-4 bg-white border-r-[1px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-thumb-rounded`}
          >
            {/* User box */}
            <div className='relative flex flex-col items-center p-5 pr-3 bg-white mb-3 border-b-[1px]'>
              <a href='https://komete.kyu.fr/' target='_blank' rel='noreferrer'>
                <Image
                  alt={'logo-komete'}
                  src={'/logo-komete-vertical.svg'}
                  width={100}
                  height={100}
                  className='w-[80px] cursor-pointer'
                />
              </a>

              <h1 className='flex flex-col items-center text-sm font-semibold tracking-tight text-primary-blue-300 mx-2 mt-1'>
                <span>Bonjour,</span>
                <span className='text-primary-blue-400'>
                  {userNameWithoutAt}
                </span>
              </h1>
              <p
                className='underline text-pink-500 text-[10px] font-normal mb-4 cursor-pointer'
                onClick={() => {
                  session
                    ? federatedSignOut()
                    : console.log("Aucune session n'est active");
                }}
              >
                Déconnexion
              </p>
            </div>
            {/* Nav box */}
            <div className='flex flex-col p-1 bg-white border-b-[1px]'>
              {/* <h1 className="flex flex-col items-start text-sm font-semibold tracking-tight text-primary-blue-300 mx-2 mt-1">
            <span>Choisissez votre thématique</span>
          </h1> */}
              <div className='flex flex-col items-start text-sm font-semibold tracking-tight text-primary-blue-300 mx-2 mt-1'>
                <NavigationMenu />
              </div>
            </div>
            {/* Export and methodologie buttons */}
            <div className='flex flex-col p-5 bg-white'>
              <div className='flex flex-col items-center text-sm xl:text-base font-semibold tracking-tight text-primary-blue-300 p-1'>
                {/* Export button */}
                <div className='relative w-full'>
                  <>
                    <div
                      className={`text-opacity-90 justify-center cursor-pointer flex flex-row gap-1 bg-[#BB1B49] hover:bg-[#bb1b4b70] text-white font-base text-sm py-2 px-3 h-fit mt-0.5 rounded-lg`}
                      onClick={() => {
                        setShowExportIcons(!showExportIcons);
                      }}
                    >
                      <span className='w-full text-center'>
                        Exporter le rapport
                      </span>
                    </div>
                    <Transition
                      show={showExportIcons}
                      enter='transition ease-out duration-200'
                      enterFrom='opacity-0 translate-y-1'
                      enterTo='opacity-100 translate-y-0'
                      leave='transition ease-in duration-150'
                      leaveFrom='opacity-100 translate-y-0'
                      leaveTo='opacity-0 translate-y-1'
                    >
                      <div className='flex justify-center py-2 cursor-pointer'>
                        <Image
                          src={'/feuille.svg'}
                          width={30}
                          height={60}
                          alt='Excel'
                          className='rounded hover:bg-slate-200'
                          onClick={handleExportReportExcel}
                        ></Image>
                        <Image
                          src={'/png.svg'}
                          width={30}
                          height={60}
                          alt='Png'
                          className='ml-5 rounded hover:bg-slate-200'
                          onClick={handleExportReportPNG}
                        ></Image>
                      </div>
                    </Transition>
                  </>
                </div>
                {/* Note méthodologique */}
                <Popover className='relative w-full'>
                  {({ open }) => (
                    <>
                      <Popover.Button
                        onClick={handleShowMethodology}
                        className={`${open ? '' : 'text-opacity-90'} justify-center cursor-pointer
                                        flex flex-row gap-1 bg-[#BB1B49] hover:bg-[#bb1b4b70] text-white font-base text-sm py-2 px-3 h-fit mt-0.5 rounded-lg`}
                      >
                        <span className='w-full'>
                          Méthodologie & qualité des données
                        </span>
                      </Popover.Button>
                      <Transition
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                      ></Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
            <Transition
              show={!!showWarningMessage}
              enter='transition ease-out duration-200'
              enterFrom='opacity-0 translate-y-1'
              enterTo='opacity-100 translate-y-0'
              leave='transition ease-in duration-150'
              leaveFrom='opacity-100 translate-y-0'
              leaveTo='opacity-0 translate-y-1'
            >
              <span className='text-[12px] font-normal text-gray-500 text-center block'>
                {showWarningMessage}
              </span>
            </Transition>
          </div>
        ) : (
          <div className='absolute bottom-2.5 right-2.5 flex flex-col '>
            {showExportIconsMobile && (
              <>
                <button
                  type='button'
                  className='text-white mt-2 p-1.5 z-40 bg-gray-200 shadow-sm hover:bg-white hover:text-[#BB1B49] hover:border-[#BB1B49] hover:border-[1px] rounded-lg text-sm inline-flex items-center'
                  onClick={() => {
                    handleExportReportPNG();
                    setShowExportIconsMobile(false);
                  }}
                >
                  <Image
                    height={20}
                    width={20}
                    alt='export_png'
                    src={'/png.svg'}
                  />
                  <span className='sr-only'>Export png</span>
                </button>
                <button
                  type='button'
                  className='text-white mt-2 p-1.5 z-40 bg-gray-200 shadow-sm hover:bg-white hover:text-[#BB1B49] hover:border-[#BB1B49] hover:border-[1px] rounded-lg text-sm inline-flex items-center'
                  onClick={() => {
                    handleExportReportExcel();
                    setShowExportIconsMobile(false);
                  }}
                >
                  <Image
                    height={20}
                    width={20}
                    alt='export_excel'
                    src={'/feuille.svg'}
                  />
                  <span className='sr-only'>Export Excel</span>
                </button>
              </>
            )}
            <button
              type='button'
              className='text-white mt-2 p-1.5 z-40 bg-[#BB1B49] hover:bg-white hover:text-[#BB1B49] hover:border-[#BB1B49] hover:border-[1px] rounded-lg text-sm inline-flex items-center'
              onClick={() => {
                setShowExportIconsMobile(!showExportIconsMobile);
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-5 h-5'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                />
              </svg>
              <span className='sr-only'>Close menu</span>
            </button>
          </div>
        )}
      </aside>
    </>
  );
}
